<template>
	<div>
		<nav class="bd-header bg-white py-2 d-flex align-items-stretch border-bottom border-gray navbar navbar-expand-md navbar-light">
			<div class="container">
				<router-link :to="{path: '/'}" class="navbar-brand">
					<h1 class="d-flex align-items-center fs-4 mb-0">
						<img src="//iwstatic.g.bsrv.su/img/panel/bylex-logo.png" width="50" height="50" class="me-3" alt="byLex" />
						{{$t('logo-text')}}
					</h1>
				</router-link>
				<div class="me-auto"></div>
			</div>
		</nav>
		<div class="d-flex align-items-center">
			<div class="container m-auto pt-5">
				<router-view/>
			</div>
		</div>
		<footer class="py-3">
			<div class="container text-center">
				<a
					href="#"
					class="btn btn-link text-muted"
					@click="$root.$i18n.locale = lang"
					v-for="(lang, i) in appModel.langs.filter(item => item != $root.$i18n.locale)"
					:key="`lang-${i}`"
				>
					<svg class="footer-links__icon footer-links__icon-ball" width="16" height="16">
						<use xlink:href="/assets/images/icons-sprite.svg#globus"></use>
					</svg>
					{{appModel.langsName[lang]}}
				</a>
				<router-link :to="{path: '/'}" class="btn btn-link text-muted">{{$t('link-about')}}</router-link>
				<router-link :to="{path: '/projects'}" class="btn btn-link text-muted">{{$t('link-projects')}}</router-link>
				<router-link :to="{path: '/feedback'}" class="btn btn-link text-muted">{{$t('link-feedback')}}</router-link>
			</div>
		</footer>
	</div>
</template>

<style lang="sass" scoped>
.svg-icon {
	padding: 0;
    font-size: 0;
    flex: none;
}
.svg-icon_exit {
    display: inline-block;
}
.btn-exit {
	padding-top: 2px;
}
.btn:hover .svg-icon_exit {
	fill: #fff;
}
.footer-links__icon {
	font-size: 0;
	line-height: 1;
	display: inline-block;
	vertical-align: middle;
}
.footer-links__icon-ball {
	margin-right: 5px;
	fill: currentColor;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'byLex'});
	},
	data: () => ({
		
	}),
	methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	mounted(){},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.authModel.doLogout();
				}
				throw err;
			});
		});
	},
	computed: {},
	components: {},
}
</script>