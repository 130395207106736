<template>
	<div class="row mb-3 align-items-center justify-content-center">
		<div class="col">
			<div class="text-center">
				<div>
					<div class="row justify-content-center">
						<div class="col-sm-4">
							<div class="error-img">
								<img src="/assets/images/404-error.png" alt="" class="img-fluid mx-auto d-block">
							</div>
						</div>
					</div>
				</div>
				<h4 class="text-uppercase mt-4">{{$t('header-404')}}</h4>
				<p class="text-muted">{{$t('text-404')}}</p>
				<div class="mt-4">
					<router-link :to="{path: '/'}" class="btn btn-primary waves-effect waves-light">{{$t('btn-goto-main')}}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Страница не найдена | byLex'});
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>