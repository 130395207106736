import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout.vue';

import InfoAbout from '@/pages/InfoAbout.vue';
import Projects from '@/pages/Projects.vue';
import Feedback from '@/pages/Feedback.vue';

import Test1 from '@/pages/Test1.vue';
import Test2 from '@/pages/Test2.vue';

import PageNotFound from '@/pages/PageNotFound.vue';

const AuthComponent = {
	template: '<div class="auth-component"></div>',
};

const routes = [
	/*{
		path: '/',
		redirect: '/index',
	},*/
	{
		path: '/',
		component: Layout,
		children: [
			/*{
				path: '/',
				redirect: '/settings',
			},*/
			{
				path: '/',
				component: InfoAbout,
			},
			{
				path: '/projects',
				component: Projects,
			},
			{
				path: '/feedback',
				component: Feedback,
			},
			
			
			{
				path: '/test1',
				component: Test1,
			},
			{
				path: '/test2',
				component: Test2,
			},
			
			{
				path: '/:pathMatch(.*)*',
				component: PageNotFound,
			},
			{
				path: '/:pathMatch(.*)',
				component: PageNotFound,
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				next({
					//path: '/login',
					path: '/',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				if(to.matched.some(record => record.meta.is_admin)){
					if(storeInstance.state.app.auth.isAdmin()){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next() 
		}
	});
});
/*router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.userProfile !== null) next({path: '/'})
  	else next()
})*/

export default router;