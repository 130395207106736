import { createApp } from 'vue/dist/vue.esm-bundler';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import axios, { AxiosInstance } from 'axios';
import VueAxios from 'vue-axios';
import { UniversalSocialauth } from 'universal-social-auth';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { FontAwesomeIcon } from '@/assets/plugins/font-awesome';
import Toaster from '@meforma/vue-toaster';
import app_mixin from "./mixin.js";
import lib from '@/lib';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
//import '@/assets/css/bootstrap.min.css';
//import '@/assets/css/icons.min.css';
//import '@/assets/css/app.min.css';
//import '@/assets/css/style.css';
import '@/assets/js/date.format.js';
import '@/main.scss';

const options = {
	providers: {
		discord: {
			clientId: '1053989647618949191',
			redirectUri: 'https://id.bylex.su/api/social-auth/discord/callback',
		},
		facebook: {
			clientId: '890869655270253',
			redirectUri: 'https://id.bylex.su/api/social-auth/facebook/callback',
		},
		github: {
			clientId: '282622da02a2d647af6d',
			redirectUri: 'https://id.bylex.su/api/social-auth/github/callback',
		},
		google: {
			clientId: '1085776844737-jmfvmqud6gs35cases6q6o2t6klcqpvl.apps.googleusercontent.com',
			redirectUri: 'https://id.bylex.su/api/social-auth/google/callback',
		},
		mailru: {
			clientId: '4cf4d6a8a1ad42929b46c8ee2363d9ee',
			redirectUri: 'https://id.bylex.su/api/social-auth/mailru/callback',
		},
		/*microsoft: {
			clientId: '03abe4ca-ab68-40b5-9082-0b8386ea0766',
			redirectUri: 'https://id.bylex.su/api/social-auth/microsoft/callback',
		},*/
		/*steam: {
			clientId: '',
			redirectUri: 'https://id.bylex.su/api/social-auth/steam/callback',
		},*/
		/*telegram: {
			clientId: '',
			redirectUri: 'https://id.bylex.su/api/social-auth/telegram/callback',
		},*/
		twitch: {
			clientId: 'r728w7eo9x894mz0iza6kmzlb4hocn',
			redirectUri: 'https://id.bylex.su/api/social-auth/twitch/callback',
		},
		/*twitter: {
			clientId: '',
			url: 'https://id.bylex.su/api/social-auth/twitter/callback',
			redirectUrl: 'https://id.bylex.su/api/social-auth/twitter/callback',
		},*/
		vkontakte: {
			clientId: '51505666',
			redirectUri: 'https://id.bylex.su/api/social-auth/vkontakte/callback',
		},
		yandex: {
			clientId: 'b5fc0fd2d7f04c949e7bfc2e65693661',
			redirectUri: 'https://id.bylex.su/api/social-auth/yandex/callback',
		},
	}
}
const Oauth = new UniversalSocialauth(axios, options);

const app = createApp(App);
const metaManager = createMetaManager();

window['appInstance'] = null;
window['appComponent'] = null;
window['storeInstance'] = null;

app.config.globalProperties.$Oauth = Oauth;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
//app.config.productionTip = false;
//app.config.debug = true;
//app.config.devtools = true;
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(store.state.app.i18n);
app.use(metaManager);
app.use(Toaster);
app.component('font-awesome-icon', FontAwesomeIcon);
lib.addMixin(app_mixin);
app.mount('#app');

window['lib'] = lib;
window['axios'] = axios;

window['appInstance'] = app;
window['storeInstance'] = store;
//window['routerInstance'] = router;

// Запускаем скрипт проверки авторизации
setTimeout(() => {
	//storeInstance.state.auth.SSI_step1();
}, 100);
