<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-12 col-lg-10 col-xl-8">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="mt-2">
						<h4>Проекты платформы «byLex»</h4>
					</div>
					<div class="p-2 mt-4">
						<div class="list-group">
							<a :href="project.link" target="_blank" class="list-group-item list-group-item-action" v-for="project in projects">{{project.name}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Проекты платформы «byLex» | byLex'});
	},
	data: () => ({
		projects: [
			{
				name: 'byLex Аккаунт',
				link: 'https://id.bylex.su',
			},
			{
				name: 'byLex Игры',
				link: 'https://games.bsrv.su',
			},
		],
	}),
	methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		//Navbar,
	},
};
</script>