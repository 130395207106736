<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-12 col-lg-10 col-xl-8">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="mt-2">
						<h4>Единый аккаунт платформы «byLex»</h4>
					</div>
					<div class="p-2 mt-4">
						<p>Платформа «byLex» поддерживает и развивает несколько тесно связанных между собой IT-ресурсов, расположенных по адресу: <a href="https://bylex.su">bylex.su</a>, а также игр, расположенных по адресу: <a href="https://games.bsrv.su" target="_blank">games.bsrv.su</a></p>
						<p>После регистрации на <a href="https://id.bylex.su" target="_blank">id.bylex.su</a> вы получаете доступ и к остальным нашим сервисам — входить на них можно в один клик. Если у вас уже есть аккаунт, просто используйте логин и пароль от «byLex Аккаунта». Для удобства можно привязать к учетной записи аккаунты в социальных сетях.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Единый аккаунт платформы «byLex» | byLex'});
	},
	data: () => ({
		
	}),
	methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		//Navbar,
	},
};
</script>